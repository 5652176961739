import React,{useState} from "react";
import "../../App.css";
import HeroSection from "../HeroSection.js";
import Card from '../Card.js'

function Home() {

  return (
  <>
  <HeroSection />
  <Card />

  </>
  )
}

export default Home;
